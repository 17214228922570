body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    text-decoration: none;
    color: black;
}

a:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.349);
}

.hidden {
    visibility: hidden;
}

.title {
    padding-top: 15px;
}

#home-header-background {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    width: 100%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: inherit;
    z-index: 20;
    text-align: center;
}

#membership-header-background, #documents-header-background {
    background-color: transparent;
    background-repeat: no-repeat;
    /*background-image: url("//tangentindia.org/wp-content/uploads/2019/02/memeber.jpg");*/
    background-size: cover;
    background-position: center bottom;
    width: 100%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: inherit;
    z-index: 20;
    text-align: center;
}

#newsletter-header-background, #about-header-background, #team-header-background {
    background-color: rgba(255, 255, 255, 0);
    /*background-image: url("http://tangentindia.org/wp-content/uploads/2019/02/events.jpg");*/
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 0px;
    padding-right: 30px;
    padding-bottom: 0px;
    padding-left: 30px;
    margin-bottom: 0px;
    margin-top: 0px;
    border-width: 0px 0px 0px 0px;
    border-color: #eae9e9;
    border-style: solid;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#contact-header-background {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    width: 100%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: inherit;
    z-index: 20;
    text-align: center;
}

#events-header-background {
    background-color: rgba(255, 255, 255, 0);
    /*background-image: url("//tangentindia.org/wp-content/uploads/2019/02/events.jpg");*/
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 0px;
    padding-right: 30px;
    padding-bottom: 0px;
    padding-left: 30px;
    margin-bottom: 0px;
    margin-top: 0px;
    border-width: 0px 0px 0px 0px;
    border-color: #eae9e9;
    border-style: solid;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.header-foreground {
    width: 30%;
    height: 30%;
    margin: 3%;
}

.small-image {
    width: 90%;
    margin: auto;
}

.center {
    text-align: center;
    margin: auto;
}

.grey-bg {
    background-color: #e5e5e5;
}

.section {
    padding-top: 5%;
    padding-bottom: 5%;
}

.section-header {
    font-size: 400%;
    font-weight: bold;
}

.section-subheader {
    font-size: 200%;
    font-weight: bold;
}

.objective {
    padding: 2%;
    font-size: 150%;
}

.small-objective {
    padding: 2%;
    font-size: 100%;
}

.fa-li {
    height: 50%;
    color: #f37831;
}

.board-members, .office-bearers {
    width: 100%;
    display: flex;
}

.board-member, .office-bearer {
    width: 200px;
    padding: 20px;
    margin-top: 0;
}

.board-member-info {
    margin-top: 10px;
}

.iframe {
    border: 0;
    width: 100%;
}

@media print {
    .directory-container {
        display: none;
    }
}

.small-padding {
    margin: 10px;
}

.header-social-media-icons {
    height: 3%;
    width: 100%
}

.header-social-icon {
    padding: 0.5%;
    color: #f47932;
}

.bg-grey {
    background-color: #e5e5e5;
}

.footer {
    color: #747474;
    border-top: 5px solid #f47932;
}

.footer-logo {
    width: 70%;
    margin: 3%;
}

.footer-header {
    color: #f47932;
    font-size: 150%;
    font-weight: bold;
    padding-bottom: 15%;
}

.light-bg {
    background-color: #f7f7f7;
}

.iframe {
    border: 0;
    width: 100%;
    height: 210px;
}

.social-icon {
    padding: 5%;
    color: #f47932;
}

.social-icons {
    margin-top: 20%;
}

.bottom-half-positioned {
    position: fixed;
    bottom: 30%;
}